.react-table-main { overflow-x: auto; border: 1px solid #ddd; }
.react-table-main .zero-padding { padding: 0; }
.react-table-main .table { position: relative; font-size: 0.875rem; margin: 0; width: 100%; -webkit-border-horizontal-spacing: inherit; }
.react-table-main .table thead{ text-align: left; }
.react-table-main .table .header-row th:first-child{ padding: 1.5em 0.5em 1.5em 1.5em; }
.react-table-main .table .header-row th{ padding: 1.5em 0.5em; vertical-align: middle; border-bottom: 1px solid #dddddd; }
.react-table-main .table .header-row th:last-child{ padding: 1.5em 1.5em 1.5em 0.5em; }
.react-table-main .button-progress {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -12px;
    margin-left: -12px;
}
.react-table-main .progress-overlay {
    background: #0000001c;
    width: 100%;
    height: 100%;
    top: 0;
    position: absolute;
    text-align: center;
}
.react-table-main .table .header-filter-row th:first-child{ padding: 0.5em 0.5em 0.5em 1.5em; }
.react-table-main .table .header-filter-row th{ padding: 0.5em 0.5em; vertical-align: middle; border-bottom: 1px solid #dddddd; }
.react-table-main .table .header-filter-row th:last-child{ padding: 1.5em 1.5em 1.5em 0.5em; }
.react-table-main .table .header-filter-row .filter-textfield input{ padding: 5.5px 5.5px 5.5px 7.5px; }
.react-table-main .table .header-filter-row .filter-textfield .MuiOutlinedInput-adornedEnd{ padding-right:0; }
.react-table-main .table .header-filter-row .filter-textfield .MuiInputAdornment-positionEnd{ margin-left:0; padding-bottom: 2px; }
.react-table-main .table .header-filter-row .filter-selectfield .MuiSelect-select{ padding: 5.5px 30.5px 5.5px 7.5px; }
.react-table-main .table .header-filter-row .filter-selectfield .MuiSelect-select:focus{ border-radius: 4px;border: 1px solid #ced4da; background: transparent }
.react-table-main .table .data-row td:first-child{ padding: 0.5em 0.5em 0.5em 1.5em; }
.react-table-main .table .data-row td{ padding: 0.5em; vertical-align: middle; border-bottom: 1px solid #dddddd; }
.react-table-main .table .cursor-pointer{ cursor: pointer; }
.react-table-main .table .data-row td:last-child{ padding: 0.5em 1.5em 0.5em 0.5em; }
.react-table-main .table .align-center { text-align: center;}
.react-table-main .table-pagination-footer .MuiTablePagination-selectLabel, .react-table-main .table-pagination-footer .MuiTablePagination-displayedRows{ margin: 0; }
.react-table-main .sort-arrow { margin-left: 5px; color: #869195; }